import React from 'react'
import styled from 'styled-components'
import CheckIcon from '@material-ui/icons/CheckRounded'
import Typography from '@material-ui/core/Typography'
import { lightTheme } from '@admin/theme'

interface Props {
  className?: string
  source?: string
  label?: string
  checked?: boolean
  onClick(): void
}

export const CompanyItem = ({ className, source, label, checked, onClick }: Props) => {
  return (
    <Root className={className} onClick={onClick}>
      {label ? (
        <LabelContainer>
          <Typography variant="body2">{label}</Typography>
        </LabelContainer>
      ) : (
        <Image src={source} />
      )}
      {checked ? (
        <Checked>
          <CheckIcon />
        </Checked>
      ) : null}
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
`

const Checked = styled.div`
  position: absolute;
  right: -4px;
  bottom: -4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  background: ${lightTheme.palette.primary.main};
  color: ${lightTheme.palette.background.paper};
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 16px;
    height: 16px;
  }
`

const LabelContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  background: #000000;
  color: ${lightTheme.palette.background.paper};
  display: flex;
  align-items: center;
  justify-content: center;
`
