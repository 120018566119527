import { ToggleLeftMenu, Logout } from '@admin/constants/actionTypes'
import { Action } from '@para-components/utils/reduxUtils'
import { UiState } from './types'

const initialState: UiState = {
  leftMenu: {
    collapsed: false,
  },
}

export const uiReducer = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ToggleLeftMenu.type.SUCCESS:
      return {
        ...state,
        leftMenu: action.payload,
      }
    case Logout.type.SUCCESS:
      return initialState
    default:
      return state
  }
}
