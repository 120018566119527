import React from 'react'
import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'
import { WebhookEnv } from '@admin/constants/webhooks'

interface Props {
  list: Array<WebhookEnvironmentOption>
  selectedEnvironment: WebhookEnv
  setSelectedEnvironment(value: WebhookEnv): void
}

export interface WebhookEnvironmentOption {
  name: WebhookEnv
  label: string
  url: string
}

export const WebhooksEnvironments = ({
  list,
  selectedEnvironment,
  setSelectedEnvironment,
}: Props) => {
  return (
    <Root>
      {list.map((item) => (
        <StyledChip
          key={item.name}
          label={item.label}
          color={selectedEnvironment === item.name ? 'primary' : 'default'}
          clickable
          onClick={() => setSelectedEnvironment(item.name)}
        />
      ))}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledChip = styled(Chip)`
  margin-right: 10px;
  margin-bottom: 10px;
`
