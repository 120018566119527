import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getLeftMenu } from '@admin/selectors/ui'
import { LeftMenu } from './LeftMenu'

interface Props {
  children: ReactNode
}

export const SecurePage = ({ children }: Props) => {
  const leftMenuState = useSelector(getLeftMenu)

  return (
    <Root>
      <LeftMenu />
      <Content fullWidth={leftMenuState.collapsed}>{children}</Content>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
`

const Content = styled.div<{ fullWidth?: boolean }>`
  flex: 1;
  padding: ${({ fullWidth }) => (fullWidth ? '0 0 0 60px' : '0 0 0 260px')};
`
