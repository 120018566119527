import { State } from '../reducers/types'
import { Webhook } from '../interfaces/webhooks'

export const getWebhooks = (state: State): Array<Webhook> => state.webhooks.list

export const getMatchedWebhooks = (url?: string | null) => (state: State): Array<Webhook> => {
  const webhooks = getWebhooks(state)

  if (!url) {
    return webhooks
  }

  return webhooks.filter((webhook) => webhook.url.includes(url))
}

export const getListInitiallyLoaded = (state: State): boolean => state.webhooks.listInitiallyLoaded
