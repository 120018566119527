import {
  FetchAdminUsers,
  SetLastClickedAdminUser,
  RemoveAdminPermission,
  Logout,
} from '@admin/constants/actionTypes'
import { Action } from '@para-components/utils/reduxUtils'
import { AdminUsersState } from './types'

const initialState: AdminUsersState = {
  list: [],
  lastClickedUser: null,
}

export const adminUsersReducer = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case FetchAdminUsers.type.SUCCESS:
      return {
        ...state,
        list: action.payload,
      }
    case SetLastClickedAdminUser.type.REQUEST:
      return {
        ...state,
        lastClickedUser: action.payload,
      }
    case RemoveAdminPermission.type.SUCCESS:
      return {
        ...state,
        lastClickedUser: null,
      }
    case Logout.type.SUCCESS:
      return initialState
    default:
      return state
  }
}
