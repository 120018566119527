import React, { useState, useCallback, useMemo, memo } from 'react'
import ReactSelect from 'react-select'
import styled from 'styled-components'
import { lightTheme } from '../../theme'

export interface SelectOption {
  label: string
  value: string | number
}

export interface SelectProps {
  className?: string
  id: string
  label?: string
  value?: SelectOption
  options: Array<SelectOption>
  placeholder?: string
  customStyles?: { [x: string]: any }
  noOptionsMessage?: string
  additionalProps?: { [x: string]: any }
  error?: string
  touched?: boolean
  noOffset?: boolean
  isDisabled?: boolean
  onChange(option: SelectOption): void
}

export const Select = memo(
  ({
    className,
    id,
    label,
    value,
    options,
    placeholder,
    customStyles,
    noOptionsMessage,
    additionalProps,
    error,
    touched,
    noOffset,
    isDisabled,
    onChange,
  }: SelectProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleOpen = useCallback(() => {
      setIsOpen(true)
    }, [])

    const handleClose = useCallback(() => setIsOpen(false), [])

    const data = useMemo(() => options || [], [options])

    const getNoOptionsMessage = useCallback(() => noOptionsMessage || 'No options', [
      noOptionsMessage,
    ])

    const handleGetOptionLabel = useCallback((option: SelectOption) => option.label, [])

    return (
      <Root className={className} noOffset={noOffset}>
        {label ? <Label>{label}</Label> : null}
        <SelectContainer>
          <StyledSelect
            isOptionSelected
            isSearchable
            id={id}
            className="styled-select__container"
            classNamePrefix="styled-select"
            options={data}
            value={value}
            getOptionLabel={handleGetOptionLabel}
            onChange={onChange}
            placeholder={placeholder}
            styles={customStyles}
            noOptionsMessage={getNoOptionsMessage}
            menuIsOpen={isOpen}
            onMenuOpen={handleOpen}
            onMenuClose={handleClose}
            isDisabled={isDisabled}
            {...additionalProps}
          />
        </SelectContainer>
        {error && touched ? <Error>{error}</Error> : null}
      </Root>
    )
  },
)

const Root = styled.div<Pick<SelectProps, 'noOffset'>>`
  position: relative;
  font-size: 16px;
  color: ${lightTheme.palette.text.primary};
  width: 100%;
  letter-spacing: 0.16px;
  margin-bottom: 30px;
  ${({ noOffset }) => (noOffset ? 'margin-bottom: 0;' : '')}
`

const StyledSelect = styled(ReactSelect)`
  width: 100%;
  height: 100%;
  text-transform: none;
  border: 1px solid ${lightTheme.palette.divider};
  border-radius: 4px;
  height: 32px;

  .styled-select__control {
    border: none;
    border-radius: 0;
    min-height: 0;
    box-shadow: none;
    height: 100%;
    cursor: pointer;
    background: transparent;
    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  .styled-select__menu {
    z-index: 1000;
  }

  .styled-select__value-container {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    padding: 0 12px;
  }

  .styled-select__indicator-separator {
    display: none;
  }

  .styled-select__indicator {
    padding: 0 6px 0 0;
  }

  .styled-select__dropdown-indicator {
    svg {
      height: 22px;
      width: 22px;
    }
  }

  .styled-select__single-value {
    font-size: 16px;
  }
`

const Label = styled.div`
  margin-bottom: 4px;
`

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`

const Error = styled.div`
  position: absolute;
  bottom: -24px;
  left: 0;
`
