import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import { FetchWebkooks, AddWebhook, RemoveWebhook } from '@admin/constants/actionTypes'
import { FunctionKeys } from '@admin/constants/functions'
import { AvailableWebhooks, WebhookEnv } from '@admin/constants/webhooks'
import { AsyncAction } from '@para-components/utils/reduxUtils'
import { callSecureApi } from './api/callApi'

function* onFetchWebkooks(action: AsyncAction<unknown>) {
  try {
    const { payload } = yield call(callSecureApi, FunctionKeys.ListWebhooks)

    yield put(FetchWebkooks.success(payload))
    if (action.next) {
      action.next()
    }
  } catch (err) {
    yield put(FetchWebkooks.failed(err))
    if (action.next) {
      action.next(err)
    }
  }
}

const addWebhookMap = {
  [AvailableWebhooks.AccountsRemoved]: FunctionKeys.SubscribeAccountsRemovedWebhook,
  [AvailableWebhooks.ActivitiesAdded]: FunctionKeys.SubscribeActivitiesAddedWebhook,
  [AvailableWebhooks.ActivitiesPartiallySynced]:
    FunctionKeys.SubscribeActivitiesPartiallySyncedWebhook,
  [AvailableWebhooks.ProfilesAdded]: FunctionKeys.SubscribeProfilesAddedWebhook,
}

interface AddWebhookRequest {
  webhookType: AvailableWebhooks
  env: WebhookEnv
}

function* onAddWebhook(action: AsyncAction<AddWebhookRequest>) {
  const { webhookType, env } = action.payload

  try {
    const { payload } = yield call(callSecureApi, addWebhookMap[webhookType], {
      method: 'POST',
      body: {
        env,
      },
      returnResponse: true,
    })

    yield put(AddWebhook.success(payload))
    if (action.next) {
      action.next()
    }
  } catch (err) {
    yield put(AddWebhook.failed(err))
    if (action.next) {
      action.next(err)
    }
  }
}

interface RemoveWebhookRequest {
  id: string
}

function* onRemoveWebhook(action: AsyncAction<RemoveWebhookRequest>) {
  const { id } = action.payload

  try {
    const { payload } = yield call(callSecureApi, `${FunctionKeys.RemoveWebhook}/${id}`, {
      method: 'DELETE',
      returnResponse: true,
    })

    yield put(RemoveWebhook.success(payload))
    if (action.next) {
      action.next()
    }
  } catch (err) {
    yield put(RemoveWebhook.failed(err))
    if (action.next) {
      action.next(err)
    }
  }
}

export function* webhooksSaga() {
  yield takeLatest(
    [FetchWebkooks.type.REQUEST, AddWebhook.type.SUCCESS, RemoveWebhook.type.SUCCESS],
    onFetchWebkooks,
  )
  yield takeEvery(AddWebhook.type.REQUEST, onAddWebhook)
  yield takeEvery(RemoveWebhook.type.REQUEST, onRemoveWebhook)
}
