import { fork } from 'redux-saga/effects'
import { authSaga } from './auth'
import { webhooksSaga } from './webhooks'
import { usersSaga } from './users'
import { activitiesSaga } from './activities'
import { adminUsersSaga } from './adminUsers'

export function* rootSaga() {
  yield fork(authSaga)
  yield fork(webhooksSaga)
  yield fork(usersSaga)
  yield fork(activitiesSaga)
  yield fork(adminUsersSaga)
}
