import { select, call } from 'redux-saga/effects'
import qs from 'query-string'
import firebase from 'firebase/app'
import { getUser } from '@admin/selectors/auth'

type Options = {
  headers?: {
    [x: string]: string
  }
  method?: string
  body?: any
  query?: string | Record<string, string>
  returnResponse?: boolean
}

export const callApi = async (endpoint: string, options: Options = {}) => {
  const { headers, method = 'GET', body, query, returnResponse } = options
  let requestHeaders: { [x: string]: string } = {
    'Content-Type': 'application/json',
  }
  let requestBody
  let url = 'https://us-central1-withpara-api.cloudfunctions.net/'

  if (headers) {
    requestHeaders = { ...requestHeaders, ...headers }
  }

  if (body) {
    requestBody = JSON.stringify(body)
  }

  let endpointWithQuery = endpoint
  if (query) {
    if (typeof query === 'object') {
      endpointWithQuery += `?${qs.stringify(query, { encode: false })}`
    } else if (typeof query === 'string') {
      endpointWithQuery += `?${query}`
    }
  }

  const response = await fetch(url + endpointWithQuery, {
    method,
    headers: requestHeaders,
    body: requestBody,
  })

  const responseObject = {
    status: response.status,
    ok: response.status >= 200 && response.status < 400,
    payload: returnResponse ? await response.text() : await response.json(),
  }

  if (!responseObject.ok) {
    throw responseObject
  }

  return responseObject
}

function getToken(user: firebase.User) {
  return user.getIdToken()
}

export function* callSecureApi(endpoint: string, options: Options = {}) {
  const user: firebase.User | null = yield select(getUser)

  if (!user) {
    throw new Error('Unauthorized')
  }

  const token = yield call(getToken, user)

  const optionsWithCredentials = {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${token}`,
    },
  }

  return yield call(callApi, endpoint, optionsWithCredentials)
}
