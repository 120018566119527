export type Validator = (
  value: string | number | undefined | null,
  context: any,
) => string | undefined

export const createValidator = (
  validate: (value: any, context: any) => boolean,
  errorMessage: Validator | string,
) => (value: string | number | undefined | null, context: any): string | undefined => {
  const message = typeof errorMessage === 'function' ? errorMessage(value, context) : errorMessage

  return validate(value, context) ? '' : message
}

export const composeValidators = (validators: Array<Validator>) => (value: any, context: any) =>
  validators.reduce<string | undefined>((acc, validator: Validator) => {
    if (acc) {
      return acc
    }

    return validator(value, context)
  }, '')

const isValidateSucceed = (errors: any): boolean => {
  if (typeof errors !== 'object') {
    return !errors
  }

  return Object.values(errors).every(isValidateSucceed)
}

const defaultGetKey = (_: any, idx?: number): any => idx

export const arrayValidator = (
  validator: Validator,
  getKey: (item: any, idx?: number) => any = defaultGetKey,
) => (array: Array<any>, context = {}) => {
  const errors = array.reduce((acc, value, idx) => {
    const error = validator(value, { ...context, index: idx })

    if (!isValidateSucceed(error)) {
      acc[getKey(value, idx)] = error
    }

    return acc
  }, {})

  return errors
}

export const overContext = (
  contextMapper: (value: any, context: any) => any,
  validator: Validator,
) => (value: any, context: any) => {
  return validator(value, contextMapper(value, context))
}

export const stateValidator = (validatorMap: { [x: string]: Function }) => (
  state: any,
  context?: any,
) =>
  Object.entries(state).reduce<any>((acc, [key, value]) => {
    const validator = validatorMap[key]

    if (validator) {
      const errors = validator(value, context)
      if (errors) {
        acc[key] = errors
      }
    }

    return acc
  }, {})
