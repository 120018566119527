import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { FetchUsers, SetLastClickedUser, SetSelectedUsers } from '@admin/constants/actionTypes'
import { getUsers, getLastClickedUser } from '@admin/selectors/users'
import { User } from '@admin/interfaces/users'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/SearchRounded'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { Paper } from '@admin/components/layout/Paper'
import { Select, SelectOption } from '@admin/components/shared/Select'
import { stateOptions } from '@admin/constants/address'
import { UsersGrid } from './UsersGrid'

interface Props {
  className?: string
}

export const UsersList = ({ className }: Props) => {
  const dispatch = useDispatch()

  const usersList = useSelector(getUsers)
  const lastClickedUser = useSelector(getLastClickedUser)

  const [searchValue, setSearchValue] = useState<string>('')
  const [stateValue, setStateValue] = useState<SelectOption>()

  useEffect(() => {
    dispatch(FetchUsers.request())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowClick = (user: User) => {
    dispatch(SetLastClickedUser.request(user))
  }

  const handleSelectUsers = (users: Array<User>) => {
    dispatch(SetSelectedUsers.success(users))
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value)
  }

  const usersRows = useMemo(() => {
    return usersList.filter((user) => {
      let isMatched = true

      if (!user.email) {
        return true
      }

      if (searchValue) {
        isMatched = user.email?.includes(searchValue)

        if (!isMatched) {
          return isMatched
        }
      }

      if (stateValue?.value && stateValue?.value !== -1) {
        isMatched = user.state === stateValue?.value
      }

      return isMatched
    })
  }, [usersList, searchValue, stateValue])

  return (
    <Root className={className}>
      <HeadContainer>
        <Typography variant="h4" color="textPrimary">
          Users
        </Typography>
        <FiltersContainer>
          <FormControl>
            <Input
              id="users-search"
              placeholder="Search by email"
              value={searchValue}
              onChange={handleSearchChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIconButton>
                    <SearchIcon />
                  </SearchIconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <StateSelectContainer>
            <Select
              id="state-select"
              value={stateValue}
              options={stateOptions}
              placeholder="Select State"
              onChange={setStateValue}
              noOffset
            />
          </StateSelectContainer>
        </FiltersContainer>
      </HeadContainer>
      <UsersGrid
        rows={usersRows}
        lastClickedRow={lastClickedUser?.id}
        onRowClick={handleRowClick}
        onSelectUsers={handleSelectUsers}
      />
    </Root>
  )
}

const Root = styled(Paper)`
  padding: 24px 0 6px;
`

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  margin-bottom: 30px;
`

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
`

const SearchIconButton = styled(IconButton)`
  padding: 6px;
`

const StateSelectContainer = styled.div`
  width: 200px;
  margin-left: 20px;
`
