import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { RowsProp, ColDef } from '@material-ui/data-grid'
import { DataGrid } from '@admin/components/shared/DataGrid'

interface Props {
  rows: RowsProp
}

const columns: Array<ColDef> = [
  {
    field: 'localizedDate',
    headerName: 'Date',
    align: 'center',
    headerAlign: 'center',
    width: 150,
    valueGetter: (params) =>
      params.getValue('localizedDate')
        ? moment(params.getValue('localizedDate') as string).format('MMM DD, YYYY')
        : '',
  },
  {
    field: 'localizedStartEpoch',
    headerName: 'Start Time',
    align: 'center',
    headerAlign: 'center',
    width: 100,
    valueGetter: (params) =>
      params.getValue('localizedStartEpoch')
        ? moment((params.getValue('localizedStartEpoch') as number) * 1000).format('hh:mm')
        : '',
  },
  {
    field: 'localizedEndEpoch',
    headerName: 'End Time',
    align: 'center',
    headerAlign: 'center',
    width: 100,
    valueGetter: (params) =>
      params.getValue('localizedEndEpoch')
        ? moment((params.getValue('localizedEndEpoch') as number) * 1000).format('hh:mm')
        : '',
  },
  {
    field: 'durationSeconds',
    headerName: 'Duration (mins)',
    align: 'center',
    headerAlign: 'center',
    width: 150,
    valueGetter: (params) =>
      params.getValue('durationSeconds')
        ? ((params.getValue('durationSeconds') as number) / 60).toFixed(2)
        : '',
  },
  {
    field: 'startAddress',
    headerName: 'Start Loc',
    align: 'center',
    headerAlign: 'center',
    width: 250,
  },
  {
    field: 'endAddress',
    headerName: 'End Loc',
    align: 'center',
    headerAlign: 'center',
    width: 250,
  },
]

export const UserActivitiesGrid = ({ rows }: Props) => {
  return (
    <Root>
      <DataGrid rows={rows} columns={columns} disableSelectionOnClick />
    </Root>
  )
}

const Root = styled.div`
  height: 560px;
`
