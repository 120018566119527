import { combineReducers } from 'redux'
import { authReducer } from './auth'
import { webhooksReducer } from './webhooks'
import { usersReducer } from './users'
import { adminUsersReducer } from './adminUsers'
import { uiReducer } from './ui'

export const rootReducer = combineReducers({
  auth: authReducer,
  webhooks: webhooksReducer,
  users: usersReducer,
  adminUsers: adminUsersReducer,
  ui: uiReducer,
})
