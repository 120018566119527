export const parseDigits = (string: string) => (string.match(/\d+/g) || []).join('')

export const formatDate = (string: string) => {
  const digits = parseDigits(string)
  const chars = digits.split('')
  return chars
    .reduce((r, v, index) => {
      if (index === 2 || index === 4) {
        return `${r}-${v}`
      } else if (index === 8) {
        return `${r} ${v}`
      } else if (index === 10) {
        return `${r}:${v}`
      }
      return `${r}${v}`
    }, '')
    .substr(0, 16)
}

export const formatDateWithAppend = (string: string) => {
  const res = formatDate(string)

  if (string.endsWith('-')) {
    if (res.length === 2) {
      return `${res}-`
    }

    if (res.length === 5) {
      return `${res}-`
    }
  }
  return res
}

export const appendHyphen = (value: string) =>
  value.length === 2 || value.length === 5 ? `${value}-` : value
