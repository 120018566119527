import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { User } from '@admin/interfaces/users'
import { RowsProp, ColDef, RowParams, SelectionChangeParams } from '@material-ui/data-grid'
import { DataGrid } from '@admin/components/shared/DataGrid'

interface Props {
  rows: RowsProp
  lastClickedRow?: string | null
  onRowClick(user: User): void
  onSelectUsers(users: Array<User>): void
}

const columns: Array<ColDef> = [
  { field: 'email', headerName: 'Email', align: 'center', headerAlign: 'center', width: 250 },
  { field: 'state', headerName: 'State', align: 'center', headerAlign: 'center', width: 100 },
  {
    field: 'userCreatedAt',
    headerName: 'Created at',
    align: 'center',
    headerAlign: 'center',
    width: 150,
    valueGetter: (params) =>
      params.getValue('userCreatedAt')
        ? moment((params.getValue('userCreatedAt') as number) * 1000).format('MMM DD, YYYY')
        : '',
  },
  {
    field: 'lastSignedIn',
    headerName: 'Last signed in',
    align: 'center',
    headerAlign: 'center',
    width: 150,
    valueGetter: (params) =>
      params.getValue('lastSignedIn')
        ? moment((params.getValue('lastSignedIn') as number) * 1000).format('MMM DD, YYYY')
        : '',
  },
  {
    field: 'argyleUserId',
    headerName: 'Argyle User',
    align: 'center',
    headerAlign: 'center',
    width: 150,
    valueGetter: (params) => (Boolean(params.getValue('argyleUserId')) ? 'True' : 'False'),
  },
]

export const UsersGrid = ({ rows, lastClickedRow, onRowClick, onSelectUsers }: Props) => {
  const handleRowClick = (row: RowParams) => {
    onRowClick(row.data as User)
  }

  const handleSelectUsers = (params: SelectionChangeParams) => {
    onSelectUsers(params.rows as Array<User>)
  }

  return (
    <Root>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        lastClickedRow={lastClickedRow}
        onRowClick={handleRowClick}
        onSelectionChange={handleSelectUsers}
      />
    </Root>
  )
}

const Root = styled.div`
  height: 560px;
`
