import { put, call, takeEvery } from 'redux-saga/effects'
import { v4 } from 'uuid'
import { FetchActivities, ProcessActivities } from '@admin/constants/actionTypes'
import { FunctionKeys } from '@admin/constants/functions'
import { AsyncAction } from '@para-components/utils/reduxUtils'
import { ActivityLog, AccountId } from '@admin/interfaces/users'
import { callSecureApi } from './api/callApi'

interface FetchActivitiesRequest {
  accountIds: AccountId
  fromStartDate?: string
  toStartDate?: string
}

function* onFetchActivities(action: AsyncAction<FetchActivitiesRequest>) {
  const { accountIds, fromStartDate, toStartDate } = action.payload

  try {
    const { payload } = yield call(callSecureApi, FunctionKeys.GetActivities, {
      method: 'POST',
      body: {
        accountIds,
        fromStartDate,
        toStartDate,
      },
    })

    yield put(FetchActivities.success(payload))
    if (action.next) {
      action.next()
    }
  } catch (err) {
    yield put(FetchActivities.failed(err))
    if (action.next) {
      action.next(err.payload.error)
    }
  }
}

interface ProcessActivitiesRequest {
  accountIds: Array<string>
}

function* onProcessActivities(action: AsyncAction<ProcessActivitiesRequest>) {
  try {
    const { payload } = yield call(callSecureApi, FunctionKeys.ProcessActivities, {
      method: 'POST',
      body: action.payload,
    })

    const logsPayload = payload.map((log: Omit<ActivityLog, 'id'>) => ({ id: v4(), ...log }))

    yield put(ProcessActivities.success(logsPayload))
    if (action.next) {
      action.next()
    }
  } catch (err) {
    yield put(ProcessActivities.failed(err))
    if (action.next) {
      action.next(err)
    }
  }
}

export function* activitiesSaga() {
  yield takeEvery(FetchActivities.type.REQUEST, onFetchActivities)
  yield takeEvery(ProcessActivities.type.REQUEST, onProcessActivities)
}
