import React, { useState, KeyboardEvent } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import FormHelperText from '@material-ui/core/FormHelperText'
import VisibilityIcon from '@material-ui/icons/VisibilityRounded'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffRounded'
import { lightTheme, darkTheme } from '@admin/theme'
import { createAsyncAction } from '@para-components/utils/reduxUtils'
import { Login } from '@admin/constants/actionTypes'
import { LoginFormValues } from './LoginForm.interfaces'
import { validateForm } from './LoginForm.validation'

const initialValues: LoginFormValues = {
  email: '',
  password: '',
}

export const LoginForm = () => {
  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const formValidator = (values: LoginFormValues) => validateForm(values)

  const toggleShowPassword = () => setShowPassword(!showPassword)

  const handleFormSubmit = async (values: LoginFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(dispatch, Login.request(values))
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onKeyDownHandler = (submitForm: Function) => (e: KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      submitForm()
    }
  }

  return (
    <Root>
      <Container>
        <Title variant="h1" color="textSecondary">
          Para Admin
        </Title>
        <Subtitle variant="h2" color="textPrimary">
          Log In
        </Subtitle>
        <SubtitleHint variant="subtitle1" color="textSecondary">
          Enter your email and password below
        </SubtitleHint>
        <Formik initialValues={initialValues} onSubmit={handleFormSubmit} validate={formValidator}>
          {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
            <FormContainer>
              <StyledInput
                label="Email"
                variant="outlined"
                fullWidth
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
                onKeyDown={onKeyDownHandler(handleSubmit)}
              />
              <StyledFormControl
                variant="outlined"
                fullWidth
                error={Boolean(touched.password && errors.password)}
              >
                <InputLabel htmlFor="login-password">Password</InputLabel>
                <OutlinedInput
                  id="login-password"
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  onBlur={handleBlur('password')}
                  onKeyDown={onKeyDownHandler(handleSubmit)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
                {touched.password && errors.password ? (
                  <FormHelperText id="login-password">{errors.password}</FormHelperText>
                ) : null}
              </StyledFormControl>
              {error ? (
                <ServerError variant="body2" color="secondary">
                  {error}
                </ServerError>
              ) : null}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={isLoading}
                onClick={() => handleSubmit()}
              >
                Log In
              </Button>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </Root>
  )
}

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${darkTheme.palette.primary.main};
`

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 380px;
  padding: 80px 30px 90px;
  background: ${lightTheme.palette.background.paper};
  transform: translate(-50%, -50%);
  border-radius: 8px;
  text-align: center;
`

const Title = styled(Typography)`
  color: ${lightTheme.palette.text.secondary};
  margin-bottom: 30px;
`

const Subtitle = styled(Typography)`
  margin-bottom: 12px;
`

const SubtitleHint = styled(Typography)`
  margin-bottom: 48px;
`

const FormContainer = styled(Form)``

const StyledInput = styled(TextField)`
  margin-bottom: 24px;
`

const StyledFormControl = styled(FormControl)`
  margin-bottom: 24px;
`

const ServerError = styled(Typography)`
  padding: 14px 0;
  text-align: center;
`
