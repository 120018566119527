import { call, put, takeEvery } from 'redux-saga/effects'
import firebase from 'firebase/app'
import { Login } from '@admin/constants/actionTypes'
import { AsyncAction } from '@para-components/utils/reduxUtils'

function login(email: string, password: string) {
  return firebase.auth().signInWithEmailAndPassword(email, password)
}

interface LoginRequest {
  email: string
  password: string
}

enum LoginErrorCodes {
  InvalidEmail = 'auth/invalid-email',
  UserDisabled = 'auth/user-disabled',
  UserNotFound = 'auth/user-not-found',
  WrongPassword = 'auth/wrong-password',
}

const loginErrorCodesMap = {
  [LoginErrorCodes.InvalidEmail]: 'Email is invalid',
  [LoginErrorCodes.UserDisabled]: 'User with this email address has been disabled',
  [LoginErrorCodes.UserNotFound]: 'Email address is not registered',
  [LoginErrorCodes.WrongPassword]: "Password doesn't match the email we have on record",
}

function* onLogin(action: AsyncAction<LoginRequest>) {
  const { email, password } = action.payload

  try {
    yield call(login, email, password)
    yield put(Login.success())
    if (action.next) {
      action.next(null)
    }
  } catch (err) {
    const errorCode: LoginErrorCodes = err && err.code
    const message = loginErrorCodesMap[errorCode] || err

    yield put(Login.failed(message))
    if (action.next) {
      action.next(message)
    }
  }
}

export function* loginSaga() {
  yield takeEvery(Login.type.REQUEST, onLogin)
}
