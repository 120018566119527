import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from './utils/configureStore'
import { history } from './utils/history'
import { lightTheme } from './theme'
import { Routes } from './Routes'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  html {
    background-color: ${lightTheme.palette.background.default};
    -webkit-font-smoothing: antialiased;
  }

  body {
    background-color: ${lightTheme.palette.background.default};
  }
`

export const App = () => {
  const store = configureStore()

  return (
    <Provider store={store}>
      <GlobalStyle />
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>
  )
}
