import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { lightTheme } from '@admin/theme'
import { Webhook } from '@admin/interfaces/webhooks'
import { AvailableWebhooks } from '@admin/constants/webhooks'

interface Props {
  data?: Webhook
  title: AvailableWebhooks
  isLoading: boolean
  onAddWebhook(key: AvailableWebhooks): Promise<void>
  onRemoveWebhook(key: AvailableWebhooks, id: string): Promise<void>
}

export const WebhookItem = ({ data, title, isLoading, onAddWebhook, onRemoveWebhook }: Props) => {
  const handleAddWebhook = () => onAddWebhook(title)

  const handleRemoveWebhook = () => {
    if (!data) {
      return
    }

    onRemoveWebhook(title, data.id)
  }

  return (
    <Root>
      <Title variant="h6">{title}</Title>
      {data ? (
        <>
          <KeyValueContainer>
            <Typography variant="h6" color="inherit">
              ID:&nbsp;
            </Typography>
            <Typography variant="body1" color="inherit">
              {data.id}
            </Typography>
          </KeyValueContainer>
          <KeyValueContainer>
            <Typography variant="h6" color="inherit">
              Updated at:&nbsp;
            </Typography>
            <Typography variant="body1" color="inherit">
              {data.updated_at}
            </Typography>
          </KeyValueContainer>
          <KeyValueContainer>
            <Typography variant="h6" color="inherit">
              Events:&nbsp;
            </Typography>
            <Typography variant="body1" color="inherit">
              {`["${data.events.join('", "')}"]`}
            </Typography>
          </KeyValueContainer>
          <KeyValueContainer>
            <Typography variant="h6" color="inherit">
              Config:&nbsp;
            </Typography>
            <Typography variant="body1" color="inherit">
              {data.config ? JSON.stringify(data.config) : 'null'}
            </Typography>
          </KeyValueContainer>
          <KeyValueContainer>
            <Typography variant="h6" color="inherit">
              Callback URL:&nbsp;
            </Typography>
            <Typography variant="body1" color="inherit">
              {data.url}
            </Typography>
          </KeyValueContainer>
        </>
      ) : null}
      <ButtonContainer>
        {data ? (
          <Button
            variant="contained"
            color="secondary"
            disabled={isLoading}
            onClick={handleRemoveWebhook}
          >
            Remove
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleAddWebhook}
          >
            Add
          </Button>
        )}
      </ButtonContainer>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  margin-bottom: 60px;
  &:last-child {
    margin-bottom: 0;
  }
`

const Title = styled(Typography)`
  margin-bottom: 15px;
`

const KeyValueContainer = styled.div`
  display: flex;
  line-height: 20px;
  margin-bottom: 4px;
  color: ${lightTheme.palette.primary.light};
`

const ButtonContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 0;
`
