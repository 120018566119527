import { createMuiTheme } from '@material-ui/core/styles'

const typography = {
  h1: {
    fontSize: 36,
    fontWeight: 500,
  },
  h2: {
    fontSize: 24,
    fontWeight: 500,
  },
  h4: {
    fontSize: 20,
    fontWeight: 500,
  },
  h6: {
    fontSize: 16,
    fontWeight: 500,
  },
  body1: {
    fontSize: 16,
  },
  body2: {
    fontSize: 14,
  },
  subtitle1: {
    fontSize: 14,
    marginBottom: 4,
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
  },
}

export const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#3751FF',
      light: '#4B506D',
    },
    secondary: {
      main: '#FE3008',
    },
    success: {
      main: '#06C167',
    },
    text: {
      primary: '#252733',
      secondary: '#9FA2B4',
    },
    type: 'light',
    background: {
      default: '#F7F8FC',
      paper: '#FFFFFF',
    },
    divider: '#DFE0EB',
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: 16,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 16,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
      },
    },
    MuiChip: {
      root: {
        fontSize: 12,
        fontWeight: 500,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 12,
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  typography: (palette) => ({
    fontFamily: 'Roboto, sans-serif',
    fontSize: 12,
    color: palette.text.primary,
    ...typography,
  }),
})

export const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#363740',
      light: '#9FA2B4',
    },
    secondary: {
      main: '#DDE2FF',
    },
    text: {
      primary: '#A4A6B3',
      secondary: '#DDE2FF',
    },
    type: 'dark',
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: 16,
      },
    },
  },
  typography: (palette) => ({
    fontFamily: 'Roboto, sans-serif',
    fontSize: 12,
    color: palette.text.primary,
    ...typography,
  }),
})
