import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUser, getIsUserInitialized } from '@admin/selectors/auth'
import { ROUTES } from './constants/routes'
import { LoginPage } from './pages/Login'
import { UsersPage } from './pages/Users'
import { WebhooksPage } from './pages/Webhooks'
import { AdminUsersPage } from './pages/AdminUsers'

const PublicRoutes = () => {
  return (
    <Switch>
      <Route path={ROUTES.LOGIN} component={LoginPage} exact />
      <Redirect to={ROUTES.LOGIN} />
    </Switch>
  )
}

const SecureRoutes = () => {
  return (
    <Switch>
      <Route path={ROUTES.USERS} component={UsersPage} exact />
      <Route path={ROUTES.WEBHOOKS} component={WebhooksPage} exact />
      <Route path={ROUTES.ADMIN_USERS} component={AdminUsersPage} exact />
      <Redirect to={ROUTES.USERS} />
    </Switch>
  )
}

export const Routes = () => {
  const user = useSelector(getUser)
  const isUserInitialized = useSelector(getIsUserInitialized)

  if (!isUserInitialized) {
    return null
  }

  return user ? <SecureRoutes /> : <PublicRoutes />
}
