import React from 'react'
import ReactDOM from 'react-dom'
import firebase from 'firebase/app'
import 'firebase/auth'
import NoSsr from '@material-ui/core/NoSsr'
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles'
import { App } from './App'
import { lightTheme } from './theme'

const firebaseConfig = {
  apiKey: 'AIzaSyBhfyPeaUccjolxYKyD1wjE8ILT2HyEufE',
  authDomain: 'withpara-api.firebaseapp.com',
  databaseURL: 'https://withpara-api.firebaseio.com',
  projectId: 'withpara-api',
  storageBucket: 'withpara-api.appspot.com',
  messagingSenderId: '748951601217',
  appId: '1:748951601217:web:a15dcc904f4b4b9f0146c0',
  measurementId: 'G-KX8FKN00QP',
}

firebase.initializeApp(firebaseConfig)

ReactDOM.render(
  <React.StrictMode>
    <NoSsr>
      <ThemeProvider theme={lightTheme}>
        <StylesProvider injectFirst>
          <App />
        </StylesProvider>
      </ThemeProvider>
    </NoSsr>
  </React.StrictMode>,
  document.getElementById('root'),
)
