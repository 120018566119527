import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { FetchAdminUsers, SetLastClickedAdminUser } from '@admin/constants/actionTypes'
import { getAdminUsers, getLastClickedAdminUser } from '@admin/selectors/adminUsers'
import { AdminUser } from '@admin/interfaces/adminUsers'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/SearchRounded'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { Paper } from '@admin/components/layout/Paper'
import { AdminUsersGrid } from './AdminUsersGrid'

interface Props {
  className?: string
}

export const AdminUsersList = ({ className }: Props) => {
  const dispatch = useDispatch()

  const usersList = useSelector(getAdminUsers)
  const lastClickedUser = useSelector(getLastClickedAdminUser)

  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    dispatch(FetchAdminUsers.request())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowClick = (user: AdminUser) => {
    dispatch(SetLastClickedAdminUser.request(user))
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value)
  }

  const usersRows = useMemo(() => {
    return usersList.filter((user) => user.email?.includes(searchValue))
  }, [usersList, searchValue])

  return (
    <Root className={className}>
      <HeadContainer>
        <Typography variant="h4" color="textPrimary">
          Admin Users
        </Typography>
        <FiltersContainer>
          <FormControl>
            <Input
              id="admin-users-search"
              placeholder="Search by email"
              value={searchValue}
              onChange={handleSearchChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIconButton>
                    <SearchIcon />
                  </SearchIconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </FiltersContainer>
      </HeadContainer>
      <AdminUsersGrid
        rows={usersRows}
        lastClickedRow={lastClickedUser?.id}
        onRowClick={handleRowClick}
      />
    </Root>
  )
}

const Root = styled(Paper)`
  padding: 24px 0 6px;
`

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  margin-bottom: 30px;
`

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
`

const SearchIconButton = styled(IconButton)`
  padding: 6px;
`
