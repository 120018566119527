import {
  FetchUsers,
  SetLastClickedUser,
  SetSelectedUsers,
  SetSelectedCompany,
  FetchActivities,
  ProcessActivities,
  AddAdminPermission,
  Logout,
} from '@admin/constants/actionTypes'
import { Action } from '@para-components/utils/reduxUtils'
import { UsersState } from './types'

const initialState: UsersState = {
  list: [],
  lastClickedUser: null,
  selectedUsers: [],
  activityList: [],
  activityListLoading: false,
  processActivitiesLogs: [],
  selectedCompany: null,
}

export const usersReducer = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case FetchUsers.type.SUCCESS:
      return {
        ...state,
        list: action.payload,
      }
    case SetLastClickedUser.type.REQUEST:
      return {
        ...state,
        lastClickedUser:
          action.payload.userId !== state.lastClickedUser?.userId
            ? action.payload
            : state.lastClickedUser,
      }
    case AddAdminPermission.type.SUCCESS:
      return {
        ...state,
        list: state.list.map((user) =>
          action.payload.userId === user.userId
            ? {
                ...user,
                isAdmin: true,
              }
            : user,
        ),
        lastClickedUser: {
          ...state.lastClickedUser,
          isAdmin: true,
        },
      }
    case SetSelectedUsers.type.SUCCESS:
      return {
        ...state,
        selectedUsers: action.payload,
      }
    case SetSelectedCompany.type.SUCCESS:
      return {
        ...state,
        selectedCompany: action.payload,
      }
    case FetchActivities.type.REQUEST:
      return {
        ...state,
        activityListLoading: true,
      }
    case FetchActivities.type.SUCCESS:
      return {
        ...state,
        activityList: action.payload,
        activityListLoading: false,
      }
    case FetchActivities.type.FAILED:
      return {
        ...state,
        activityListLoading: false,
      }
    case ProcessActivities.type.SUCCESS:
      return {
        ...state,
        processActivitiesLogs: action.payload,
      }
    case Logout.type.SUCCESS:
      return initialState
    default:
      return state
  }
}
