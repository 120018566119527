const BASE_URL = '/'
const LOGIN = `${BASE_URL}login`
const USERS = `${BASE_URL}users`
const WEBHOOKS = `${BASE_URL}webhooks`
const ADMIN_USERS = `${BASE_URL}admin-users`

export const ROUTES = {
  BASE_URL,
  LOGIN,
  USERS,
  WEBHOOKS,
  ADMIN_USERS,
}
