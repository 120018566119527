import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { ProcessActivities } from '@admin/constants/actionTypes'
import { createAsyncAction } from '@para-components/utils/reduxUtils'
import { getProcessedActivityLogs, getSelectedUsers } from '@admin/selectors/users'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import { Paper } from '@admin/components/layout/Paper'
import { lightTheme } from '@admin/theme'

interface Props {
  className?: string
}

export const UserActions = ({ className }: Props) => {
  const dispatch = useDispatch()

  const activityLogs = useSelector(getProcessedActivityLogs)
  const selectedUsers = useSelector(getSelectedUsers)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleProcessActivities = async () => {
    setIsLoading(true)

    try {
      const accountIds = selectedUsers.reduce<Array<string>>((acc, user) => {
        const userAccountIds = user.accountIds.map((account) => account.accountId)
        return [...acc, ...userAccountIds]
      }, [])

      await createAsyncAction(
        dispatch,
        ProcessActivities.request({
          accountIds,
        }),
      )
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Root className={className}>
      <HeadContainer>
        <Typography variant="h4" color="textPrimary">
          Action on selected user(s)
        </Typography>
      </HeadContainer>

      {isLoading ? (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      ) : (
        <PopulateActivitiesContainer>
          <ButtonsContainer>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleProcessActivities}
              disabled={selectedUsers.length === 0}
            >
              Repopulate Activities
            </Button>
          </ButtonsContainer>
          <LogsContainer>
            {activityLogs.map(({ id, ...rest }) => (
              <LogsItem key={id}>
                {Object.entries(rest).map(([key, value], idx) => (
                  <LogsItemRow key={idx}>
                    <Typography variant="subtitle2">{key}:</Typography>
                    <Typography variant="body2">{value.toString()}</Typography>
                  </LogsItemRow>
                ))}
              </LogsItem>
            ))}
          </LogsContainer>
        </PopulateActivitiesContainer>
      )}
    </Root>
  )
}

const Root = styled(Paper)`
  padding: 24px 0 6px;
`

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 30px;
`

const PopulateActivitiesContainer = styled.div``

const ButtonsContainer = styled.div`
  padding: 0 20px;
`

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 30px;
`

const LogsContainer = styled.div`
  margin-top: 30px;
  padding: 0 20px;
  max-height: 480px;
  overflow: auto;
`

const LogsItem = styled.div`
  border-top: 1px solid ${lightTheme.palette.divider};
  padding: 6px 0;
  margin-top: 14px;
`

const LogsItemRow = styled.div``
