import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import GroupIcon from '@material-ui/icons/Group'
import SettingsIcon from '@material-ui/icons/Settings'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import BackArrowIcon from '@material-ui/icons/ArrowBackIosRounded'
import { darkTheme, lightTheme } from '@admin/theme'
import { ROUTES } from '@admin/constants/routes'
import { Logout, ToggleLeftMenu } from '@admin/constants/actionTypes'
import { getLeftMenu } from '@admin/selectors/ui'

export const LeftMenu = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const leftMenuState = useSelector(getLeftMenu)

  const handleRedirect = (route: string) => () => {
    history.push(route)
  }

  const handleToggleMenu = () => {
    dispatch(ToggleLeftMenu.success({ collapsed: !leftMenuState.collapsed }))
  }

  const handleLogout = () => dispatch(Logout.request())

  return (
    <ThemeProvider theme={darkTheme}>
      <Root collapsed={leftMenuState.collapsed}>
        <Title variant="h1" color="textPrimary" collapsed={leftMenuState.collapsed}>
          Para Admin
        </Title>
        <ListContainer>
          <ListItemContainer
            onClick={handleRedirect(ROUTES.USERS)}
            isActive={location.pathname === ROUTES.USERS}
            collapsed={leftMenuState.collapsed}
          >
            <ListItemIconContainer collapsed={leftMenuState.collapsed}>
              <GroupIcon />
            </ListItemIconContainer>
            <ListItemLabelContainer collapsed={leftMenuState.collapsed}>
              Users
            </ListItemLabelContainer>
          </ListItemContainer>
          <ListItemContainer
            onClick={handleRedirect(ROUTES.WEBHOOKS)}
            isActive={location.pathname === ROUTES.WEBHOOKS}
            collapsed={leftMenuState.collapsed}
          >
            <ListItemIconContainer collapsed={leftMenuState.collapsed}>
              <SettingsIcon />
            </ListItemIconContainer>
            <ListItemLabelContainer collapsed={leftMenuState.collapsed}>
              Webhooks
            </ListItemLabelContainer>
          </ListItemContainer>
          <ListItemContainer
            onClick={handleRedirect(ROUTES.ADMIN_USERS)}
            isActive={location.pathname === ROUTES.ADMIN_USERS}
            collapsed={leftMenuState.collapsed}
          >
            <ListItemIconContainer collapsed={leftMenuState.collapsed}>
              <BusinessCenterIcon />
            </ListItemIconContainer>
            <ListItemLabelContainer collapsed={leftMenuState.collapsed}>
              Admin Users
            </ListItemLabelContainer>
          </ListItemContainer>
        </ListContainer>
        <LogoutContainer collapsed={leftMenuState.collapsed}>
          <Button color="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </LogoutContainer>
        <CollapseArrowContainer onClick={handleToggleMenu}>
          <CollapseArrow collapsed={leftMenuState.collapsed}>
            <BackArrowIcon />
          </CollapseArrow>
        </CollapseArrowContainer>
      </Root>
    </ThemeProvider>
  )
}

const Root = styled.div<{ collapsed?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ collapsed }) => (collapsed ? '60px' : '260px')};
  height: 100%;
  padding: ${({ collapsed }) => (collapsed ? '130px 0 40px' : '40px 0')};
  background: ${darkTheme.palette.primary.main};
`

const Title = styled(Typography)<{ collapsed?: boolean }>`
  text-align: center;
  margin-bottom: 48px;
  ${({ collapsed }) => (collapsed ? 'display: none;' : '')}
`

const ListContainer = styled.div`
  font-size: 16px;
`

const ListItemContainer = styled.div<{ isActive?: boolean; collapsed?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  ${({ collapsed }) => (collapsed ? 'justify-content: center;' : '')}
  height: 56px;
  padding: ${({ collapsed }) => (collapsed ? '0' : '0 0 0 32px')};
  color: ${({ isActive }) =>
    isActive ? darkTheme.palette.text.secondary : darkTheme.palette.text.primary};
  background: ${({ isActive }) => (isActive ? 'rgba(159, 162, 180, 0.08)' : 'transparent')};
  cursor: pointer;
  ${({ isActive }) =>
    isActive
      ? `
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background: #DDE2FF;
    }
  `
      : ''}
`

const ListItemIconContainer = styled.div<{ collapsed?: boolean }>`
  width: 24px;
  height: 24px;
  margin-right: ${({ collapsed }) => (collapsed ? '0' : '24px')};
  & svg {
    width: 100%;
    height: 100%;
  }
`

const ListItemLabelContainer = styled.div<{ collapsed?: boolean }>`
  ${({ collapsed }) => (collapsed ? 'display: none;' : '')}
`

const LogoutContainer = styled.div<{ collapsed?: boolean }>`
  position: absolute;
  bottom: 12px;
  right: 12px;
  ${({ collapsed }) => (collapsed ? 'display: none;' : '')}
`

const CollapseArrowContainer = styled.div`
  position: absolute;
  top: 44px;
  right: -15px;
  width: 30px;
  height: 30px;
  border: 1px solid ${lightTheme.palette.divider};
  border-radius: 50%;
  background: ${lightTheme.palette.background.paper};
  z-index: 1;
  cursor: pointer;
`

const CollapseArrow = styled.div<{ collapsed?: boolean }>`
  position: absolute;
  top: 50%;
  left: ${({ collapsed }) => (collapsed ? '6px' : '5px')};
  transform: ${({ collapsed }) =>
    collapsed ? 'translate(0, -50%) rotate(180deg)' : 'translate(0, -50%)'};
  width: 16px;
  height: 16px;
  color: ${darkTheme.palette.primary.main};
  & svg {
    width: 100%;
    height: 100%;
  }
`
