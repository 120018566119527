import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getLastClickedUser } from '@admin/selectors/users'
import { SecurePage } from '@admin/components/layout/SecurePage'
import { UsersList } from '@admin/components/users/users-list'
import { UserDetails } from '@admin/components/users/user-details'
import { UserActivities } from '@admin/components/users/user-activities'
import { UserActions } from '@admin/components/users/user-actions'

export const UsersPage = () => {
  const lastClickedUser = useSelector(getLastClickedUser)

  return (
    <SecurePage>
      <Container>
        <UsersRow>
          <StyledUsersList noOffset={!lastClickedUser} />
          <StyledUserDetails />
        </UsersRow>
        <ActivitiesRow>
          <StyledUserActivitiesList />
          <StyledUserActions />
        </ActivitiesRow>
      </Container>
    </SecurePage>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
`

const UsersRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`

const ActivitiesRow = styled.div`
  display: flex;
  width: 100%;
`

const StyledUsersList = styled(UsersList)<{ noOffset?: boolean }>`
  flex: 1;
  ${({ noOffset }) => (noOffset ? '' : 'margin-right: 20px;')}
`

const StyledUserDetails = styled(UserDetails)`
  flex: 0 0 340px;
`

const StyledUserActivitiesList = styled(UserActivities)`
  flex: 1;
  margin-right: 20px;
`

const StyledUserActions = styled(UserActions)`
  flex: 0 0 340px;
`
