import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { lightTheme } from '@admin/theme'

interface Props {
  className?: string
  children: ReactNode
}

export const Paper = ({ className, children }: Props) => {
  return <Root className={className}>{children}</Root>
}

const Root = styled.div`
  background: ${lightTheme.palette.background.paper};
  border: 1px solid ${lightTheme.palette.divider};
  border-radius: 8px;
`
