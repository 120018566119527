import { call, put, take, fork, takeLatest } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import firebase from 'firebase/app'
import { SetUser, Logout } from '@admin/constants/actionTypes'

function createAuthStateChangedChannel() {
  return eventChannel((emit) => {
    const subscriber = firebase.auth().onAuthStateChanged((user) => {
      emit({ user })
    })
    return subscriber
  })
}

function* onAuthStateChanged() {
  const authStateChangedChannel = yield call(createAuthStateChangedChannel)

  while (true) {
    try {
      const { user }: { user: firebase.User | null } = yield take(authStateChangedChannel)

      yield put(SetUser.success(user))
    } catch (err) {
      yield put(SetUser.failed(err))
    }
  }
}

function logout() {
  return firebase.auth().signOut()
}

function* onLogout() {
  try {
    yield call(logout)
    yield put(Logout.success())
  } catch (err) {
    yield put(Logout.failed(err))
  }
}

function* onLogoutSuccess() {}

export function* userSaga() {
  yield fork(onAuthStateChanged)
  yield takeLatest(Logout.type.REQUEST, onLogout)
  yield takeLatest(Logout.type.SUCCESS, onLogoutSuccess)
}
