import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { getLastClickedAdminUser } from '@admin/selectors/adminUsers'
import { RemoveAdminPermission } from '@admin/constants/actionTypes'
import { createAsyncAction } from '@para-components/utils/reduxUtils'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Paper } from '@admin/components/layout/Paper'

interface Props {
  className?: string
}

export const AdminUserDetails = ({ className }: Props) => {
  const dispatch = useDispatch()

  const lastClickedUser = useSelector(getLastClickedAdminUser)

  const [isRemoveAdminPermissionLoading, setIsRemoveAdminPermissionLoading] = useState<boolean>(
    false,
  )

  const handleRemoveAdminPermissionClick = async () => {
    setIsRemoveAdminPermissionLoading(true)
    try {
      await createAsyncAction(
        dispatch,
        RemoveAdminPermission.request({
          userId: lastClickedUser?.uid,
        }),
      )
    } catch (err) {
    } finally {
      setIsRemoveAdminPermissionLoading(false)
    }
  }

  if (!lastClickedUser) {
    return null
  }

  return (
    <Root className={className}>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        disabled={isRemoveAdminPermissionLoading}
        onClick={handleRemoveAdminPermissionClick}
      >
        Remove Admin Access
      </Button>
      <ValuesContainer>
        {lastClickedUser.email ? (
          <KeyValueContainer>
            <Typography variant="subtitle1" color="textSecondary">
              Email
            </Typography>
            <Typography variant="body1">{lastClickedUser.email}</Typography>
          </KeyValueContainer>
        ) : null}
      </ValuesContainer>
    </Root>
  )
}

const Root = styled(Paper)`
  padding: 30px 20px 20px;
  display: flex;
  flex-direction: column;
`

const ValuesContainer = styled.div`
  margin-top: 20px;
`

const KeyValueContainer = styled.div`
  margin-bottom: 15px;
`
