import React from 'react'
import styled from 'styled-components'
import { DataGrid as MuiDataGrid, DataGridProps } from '@material-ui/data-grid'
import { lightTheme } from '@admin/theme'

interface Props extends DataGridProps {
  lastClickedRow?: string | null
}

export const DataGrid = (props: Props) => {
  return <StyledDataGrid {...props} showColumnRightBorder />
}

const StyledDataGrid = styled(MuiDataGrid)<Partial<Props>>`
  font-size: 14px;
  .MuiDataGrid-columnsContainer {
    color: ${lightTheme.palette.text.secondary};
    font-weight: 500;
  }
  .MuiDataGrid-cell {
    border-bottom: 1px solid ${lightTheme.palette.divider};
  }
  .MuiDataGrid-row[data-rowindex='0'] {
    border-top: 1px solid ${lightTheme.palette.divider};
  }
  ${({ lastClickedRow }) =>
    lastClickedRow
      ? `
  & .MuiDataGrid-row[data-id='${lastClickedRow}'] {
    background: rgba(55, 81, 255, 0.2);
  }
  `
      : ''}
`
