export enum FunctionKeys {
  ListWebhooks = 'listWebhooks',
  RemoveWebhook = 'removeWebhook',
  SubscribeAccountsRemovedWebhook = 'subscribeAccountsRemovedWebhook',
  SubscribeActivitiesPartiallySyncedWebhook = 'subscribeActivitiesPartiallySyncedWebhook',
  SubscribeActivitiesAddedWebhook = 'subscribeActivitiesAddedWebhook',
  SubscribeProfilesAddedWebhook = 'subscribeProfilesAddedWebhook',

  GetUsers = 'getUsers',

  GetActivities = 'getActivities',
  ProcessActivities = 'repopulateUserHistoricalActivities',

  GetAdminUsers = 'getAdminUsers',
  AddAdminPermission = 'addAdminPermission',
  RemoveAdminPermission = 'removeAdminPermission',
}
