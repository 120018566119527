import { FetchWebkooks, Logout } from '@admin/constants/actionTypes'
import { Action } from '@para-components/utils/reduxUtils'
import { WebhooksState } from './types'

const initialState: WebhooksState = {
  list: [],
  listInitiallyLoaded: false,
}

export const webhooksReducer = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case FetchWebkooks.type.SUCCESS:
      return {
        ...state,
        list: action.payload,
        listInitiallyLoaded: true,
      }
    case Logout.type.SUCCESS:
      return initialState
    default:
      return state
  }
}
