import {
  stateValidator,
  createValidator,
  composeValidators,
} from '@para-components/utils/validation/core'
import { isRequired, isDateValid } from '@para-components/utils/validation/validators'

export const validateForm = stateValidator({
  startTime: composeValidators([
    createValidator(isRequired, 'Date is required'),
    createValidator(isDateValid, 'Date is not valid'),
  ]),
  endTime: composeValidators([
    createValidator(isRequired, 'Date is required'),
    createValidator(isDateValid, 'Date is not valid'),
  ]),
})
