import validator from 'validator'
import {
  stateValidator,
  createValidator,
  composeValidators,
} from '@para-components/utils/validation/core'
import { isRequired } from '@para-components/utils/validation/validators'

export const validateForm = stateValidator({
  email: composeValidators([
    createValidator(isRequired, 'Email is required'),
    createValidator(validator.isEmail, 'Email is invalid'),
  ]),
  password: composeValidators([createValidator(isRequired, 'Password is required')]),
})
