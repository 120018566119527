import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getLastClickedUser, getSelectedCompany } from '@admin/selectors/users'
import {
  FetchActivities,
  AddAdminPermission,
  SetSelectedCompany,
} from '@admin/constants/actionTypes'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PersonIcon from '@material-ui/icons/PersonRounded'
import { lightTheme } from '@admin/theme'
import { Paper } from '@admin/components/layout/Paper'
import { AccountId } from '@admin/interfaces/users'
import { createAsyncAction } from '@para-components/utils/reduxUtils'
import { CompanyItem } from './CompanyItem'

interface Props {
  className?: string
}

export const UserDetails = ({ className }: Props) => {
  const dispatch = useDispatch()

  const lastClickedUser = useSelector(getLastClickedUser)
  const selectedCompany = useSelector(getSelectedCompany)

  const [isAddAdminPermissionLoading, setIsAddAdminPermissionLoading] = useState<boolean>(false)

  useEffect(() => {
    dispatch(SetSelectedCompany.success(null))
  }, [lastClickedUser, dispatch])

  const handleAccountClick = (accountId: AccountId) => {
    dispatch(
      FetchActivities.request({
        accountIds: [accountId],
      }),
    )
    dispatch(SetSelectedCompany.success(accountId.dataPartner))
  }

  const handleAllAccountsClick = () => {
    dispatch(
      FetchActivities.request({
        accountIds: lastClickedUser?.accountIds,
      }),
    )
    dispatch(SetSelectedCompany.success('ALL'))
  }

  const handleAddAdminPermissionClick = async () => {
    setIsAddAdminPermissionLoading(true)
    try {
      await createAsyncAction(
        dispatch,
        AddAdminPermission.request({ userId: lastClickedUser?.userId }),
      )
    } catch (err) {
    } finally {
      setIsAddAdminPermissionLoading(false)
    }
  }

  if (!lastClickedUser) {
    return null
  }

  return (
    <Root className={className}>
      <TopContainer>
        <AvatarContainer>
          <AvatarImageContainer>
            <AvatarPlaceholder>
              <PersonIcon />
            </AvatarPlaceholder>
          </AvatarImageContainer>
          {lastClickedUser.firstName ? (
            <AvatarLabelContainer>
              <Typography variant="subtitle1" color="textSecondary">
                Full name
              </Typography>
              <Typography variant="body1">{`${lastClickedUser.firstName} ${lastClickedUser.lastName}`}</Typography>
            </AvatarLabelContainer>
          ) : null}
        </AvatarContainer>
        {lastClickedUser.email ? (
          <KeyValueContainer>
            <Typography variant="subtitle1" color="textSecondary">
              Email
            </Typography>
            <Typography variant="body1">{lastClickedUser.email}</Typography>
          </KeyValueContainer>
        ) : null}
        {lastClickedUser.city ? (
          <KeyValueContainer>
            <Typography variant="subtitle1" color="textSecondary">
              Location
            </Typography>
            <Typography variant="body1">{lastClickedUser.city}</Typography>
          </KeyValueContainer>
        ) : null}
        {lastClickedUser.argyleUserId ? (
          <KeyValueContainer>
            <Typography variant="subtitle1" color="textSecondary">
              Argyle User Id
            </Typography>
            <Typography variant="body1">{lastClickedUser.argyleUserId}</Typography>
          </KeyValueContainer>
        ) : null}
        <AccountsContainer>
          <Typography variant="subtitle1" color="textSecondary">
            Argyle Accounts
          </Typography>
          <AccountsList>
            <AccountItem
              label="ALL"
              checked={selectedCompany === 'ALL'}
              onClick={handleAllAccountsClick}
            />
            {(lastClickedUser.accountIds || []).map((accountId) => (
              <AccountItem
                key={accountId.accountId}
                source={require(`../../../../../para-components/src/assets/images/company-logo/${accountId.dataPartner}.png`)}
                checked={selectedCompany === accountId.dataPartner}
                onClick={() => handleAccountClick(accountId)}
              />
            ))}
          </AccountsList>
        </AccountsContainer>
      </TopContainer>
      <ActionsContainer>
        {!lastClickedUser.isAdmin ? (
          <AddAdminAccessButton
            variant="contained"
            color="primary"
            fullWidth
            disabled={isAddAdminPermissionLoading}
            onClick={handleAddAdminPermissionClick}
          >
            Add Admin Access
          </AddAdminAccessButton>
        ) : null}
        <Button variant="contained" color="secondary" fullWidth>
          Delete User
        </Button>
      </ActionsContainer>
    </Root>
  )
}

const Root = styled(Paper)`
  padding: 30px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TopContainer = styled.div``

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
`

const AvatarImageContainer = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 30px;
`

const AvatarPlaceholder = styled.div`
  position: relative;
  height: 100%;
  background: ${lightTheme.palette.primary.main};
  color: ${lightTheme.palette.background.paper};
  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 46px;
    height: 46px;
    transform: translate(-50%, -50%);
  }
`

const AvatarLabelContainer = styled.div``

const KeyValueContainer = styled.div`
  margin-bottom: 15px;
`

const AccountsContainer = styled.div`
  margin-bottom: 12px;
`

const AccountsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 14px 0 0;
`

const AccountItem = styled(CompanyItem)`
  margin-right: 10px;
  margin-bottom: 15px;
`

const ActionsContainer = styled.div``

const AddAdminAccessButton = styled(Button)`
  margin-bottom: 10px;
`
