export enum WebhookEnv {
  Prod = 'prod',
  Staging = 'staging',
  Sandbox = 'sandbox',
}

export enum AvailableWebhooks {
  ActivitiesAdded = 'ActivitiesAdded',
  AccountsRemoved = 'AccountsRemoved',
  ProfilesAdded = 'ProfilesAdded',
  ActivitiesPartiallySynced = 'ActivitiesPartiallySynced',
}
