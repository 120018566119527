import { SetUser, Logout } from '@admin/constants/actionTypes'
import { Action } from '@para-components/utils/reduxUtils'
import { AuthState } from './types'

const initialState: AuthState = {
  user: null,
  isUserInitialized: false,
}

export const authReducer = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case SetUser.type.SUCCESS:
      return {
        ...state,
        isUserInitialized: true,
        user: action.payload,
      }
    case Logout.type.SUCCESS:
      return initialState
    default:
      return state
  }
}
