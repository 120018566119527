import React from 'react'
import styled from 'styled-components'
import { SecurePage } from '@admin/components/layout/SecurePage'
import { WebhooksContainer } from '@admin/components/webhooks/WebhooksContainer'

export const WebhooksPage = () => {
  return (
    <SecurePage>
      <Container>
        <WebhooksContainer />
      </Container>
    </SecurePage>
  )
}

const Container = styled.div`
  padding: 60px 30px;
`
