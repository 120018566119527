import { put, call, takeEvery } from 'redux-saga/effects'
import { FetchAdminUsers, RemoveAdminPermission } from '@admin/constants/actionTypes'
import { FunctionKeys } from '@admin/constants/functions'
import { AsyncAction } from '@para-components/utils/reduxUtils'
import { AdminUser } from '@admin/interfaces/adminUsers'
import { callSecureApi } from './api/callApi'

function* onFetchAdminUsers(action: AsyncAction<unknown>) {
  try {
    const { payload } = yield call(callSecureApi, FunctionKeys.GetAdminUsers)

    const userListWithId = payload.map((user: Omit<AdminUser, 'id'>) => ({ id: user.uid, ...user }))

    yield put(FetchAdminUsers.success(userListWithId))
    if (action.next) {
      action.next()
    }
  } catch (err) {
    yield put(FetchAdminUsers.failed(err))
    if (action.next) {
      action.next(err)
    }
  }
}

interface RemoveAdminPermissionRequest {
  userId: string
}

function* onRemoveAdminPermission(action: AsyncAction<RemoveAdminPermissionRequest>) {
  try {
    yield call(callSecureApi, FunctionKeys.RemoveAdminPermission, {
      method: 'POST',
      body: {
        userId: action.payload.userId,
      },
    })

    yield put(RemoveAdminPermission.success())
    if (action.next) {
      action.next()
    }
  } catch (err) {
    yield put(RemoveAdminPermission.failed(err))
    if (action.next) {
      action.next(err)
    }
  }
}

export function* adminUsersSaga() {
  yield takeEvery(
    [FetchAdminUsers.type.REQUEST, RemoveAdminPermission.type.SUCCESS],
    onFetchAdminUsers,
  )
  yield takeEvery(RemoveAdminPermission.type.REQUEST, onRemoveAdminPermission)
}
