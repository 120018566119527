import React from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import { useRifm } from 'rifm'
import Input from '@material-ui/core/Input'
import { Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { formatDateWithAppend, appendHyphen } from '@admin/utils/maskedInput'
import { DATE_FORMAT } from '@admin/constants/date'
import { FilterFormValues } from './FilterForm.interfaces'

type Props = {
  isLoading: boolean
  error: string | null
  setFilterData: (values: FilterFormValues | null) => void
}

const UserActivitiesFilterForm = ({ isLoading, error, setFilterData }: Props) => {
  const { handleSubmit, resetForm, handleChange, values, errors, touched } = useFormikContext<
    FilterFormValues
  >()

  const startTimeMask = useRifm({
    value: values.startTime,
    onChange: handleChange('startTime'),
    accept: /\d+/g,
    mask: 16 <= values.startTime.length,
    format: formatDateWithAppend,
    append: appendHyphen,
  })
  const endTimeMask = useRifm({
    value: values.endTime,
    onChange: handleChange('endTime'),
    accept: /\d+/g,
    mask: 16 <= values.endTime.length,
    format: formatDateWithAppend,
    append: appendHyphen,
  })

  const handleClearForm = () => {
    setFilterData(null)
    resetForm()
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Title>Filter</Title>
      <InputWrapper>
        <Label>Start Time</Label>
        <Input
          type="text"
          value={startTimeMask.value}
          error={Boolean(touched.startTime && errors.startTime)}
          onChange={startTimeMask.onChange}
          placeholder={DATE_FORMAT}
        />
        {errors.startTime && touched.startTime && (
          <InputError variant="body2" color="secondary">
            {errors.startTime}
          </InputError>
        )}
      </InputWrapper>
      <InputWrapper>
        <Label>End Time</Label>
        <Input
          type="text"
          value={endTimeMask.value}
          error={Boolean(touched.endTime && errors.endTime)}
          onChange={endTimeMask.onChange}
          placeholder={DATE_FORMAT}
        />
        {errors.endTime && touched.endTime && (
          <InputError variant="body2" color="secondary">
            {errors.endTime}
          </InputError>
        )}
      </InputWrapper>
      {error ? (
        <ServerError variant="body2" color="secondary">
          {error}
        </ServerError>
      ) : null}
      <ButtonsWrapper>
        <Button onClick={handleClearForm}>Clear</Button>
        <Button disabled={isLoading} color="primary" variant="contained" type="submit">
          Apply
        </Button>
      </ButtonsWrapper>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 220px;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #4b506d;
  text-align: center;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 15px 0 10px;
`

const InputError = styled(Typography)`
  position: absolute;
  left: 0;
  bottom: -20px;
`

const Label = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  color: #9fa2b4;
`
const ServerError = styled(Typography)`
  text-align: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
`

export default UserActivitiesFilterForm
