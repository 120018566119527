import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Paper } from '@admin/components/layout/Paper'
import { AddWebhook, FetchWebkooks, RemoveWebhook } from '@admin/constants/actionTypes'
import { WebhookEnv, AvailableWebhooks } from '@admin/constants/webhooks'
import { getMatchedWebhooks, getListInitiallyLoaded } from '@admin/selectors/webhooks'
import { createAsyncAction } from '@para-components/utils/reduxUtils'
import { WebhooksEnvironments, WebhookEnvironmentOption } from './WebhooksEnvironments'
import { WebhooksList, WebhooksLoadingMap } from './WebhooksList'

const webhooksEnvironmentList: Array<WebhookEnvironmentOption> = [
  {
    name: WebhookEnv.Prod,
    label: 'us-central1-withpara-api.cloudfunctions.net',
    url: 'https://us-central1-withpara-api.cloudfunctions.net',
  },
  {
    name: WebhookEnv.Staging,
    label: 'us-central1-withpara-staging-api.cloudfunctions.net',
    url: 'https://us-central1-withpara-staging-api.cloudfunctions.net',
  },
  {
    name: WebhookEnv.Sandbox,
    label: 'us-central1-withpara-staging-sandbox-api.cloudfunctions.net',
    url: 'https://us-central1-withpara-staging-sandbox-api.cloudfunctions.net',
  },
]

export const WebhooksContainer = () => {
  const dispatch = useDispatch()

  const [selectedEnvironment, setSelectedEnvironment] = useState<WebhookEnv>(WebhookEnv.Prod)
  const [isRefreshLoading, setIsRefreshLoading] = useState<boolean>(false)
  const [webhooksLoadingMap, setWebhooksLoadingMap] = useState<WebhooksLoadingMap>({
    [AvailableWebhooks.AccountsRemoved]: false,
    [AvailableWebhooks.ActivitiesAdded]: false,
    [AvailableWebhooks.ActivitiesPartiallySynced]: false,
    [AvailableWebhooks.ProfilesAdded]: false,
  })

  const selectedEnvironmentOptions = useMemo(() => {
    return webhooksEnvironmentList.find(
      (webhookEnvironment) => webhookEnvironment.name === selectedEnvironment,
    )
  }, [selectedEnvironment]) // eslint-disable-line react-hooks/exhaustive-deps

  const webhooks = useSelector(getMatchedWebhooks(selectedEnvironmentOptions?.url))
  const webhooksInitiallyLoaded = useSelector(getListInitiallyLoaded)

  useEffect(() => {
    dispatch(FetchWebkooks.request())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchWebhooks = async () => {
    setIsRefreshLoading(true)

    try {
      await createAsyncAction(dispatch, FetchWebkooks.request())
    } catch (err) {
    } finally {
      setIsRefreshLoading(false)
    }
  }

  const handleAddWebhook = async (key: AvailableWebhooks) => {
    setWebhooksLoadingMap((state) => ({
      ...state,
      [key]: true,
    }))

    try {
      await createAsyncAction(
        dispatch,
        AddWebhook.request({
          webhookType: key,
          env: selectedEnvironment,
        }),
      )
    } catch (err) {
    } finally {
      setWebhooksLoadingMap((state) => ({
        ...state,
        [key]: false,
      }))
    }
  }

  const handleRemoveWebhook = async (key: AvailableWebhooks, id: string) => {
    setWebhooksLoadingMap((state) => ({
      ...state,
      [key]: true,
    }))

    try {
      await createAsyncAction(
        dispatch,
        RemoveWebhook.request({
          id,
        }),
      )
    } catch (err) {
    } finally {
      setWebhooksLoadingMap((state) => ({
        ...state,
        [key]: false,
      }))
    }
  }

  return (
    <Root>
      <HeadContainer>
        <HeadTitle variant="h4">Webhooks</HeadTitle>
        <Button
          variant="contained"
          color="primary"
          disabled={isRefreshLoading}
          onClick={fetchWebhooks}
        >
          Refresh
        </Button>
      </HeadContainer>
      <ChipsContainer>
        <WebhooksEnvironments
          list={webhooksEnvironmentList}
          selectedEnvironment={selectedEnvironment}
          setSelectedEnvironment={setSelectedEnvironment}
        />
      </ChipsContainer>
      <WebhooksList
        data={webhooks}
        webhooksLoadingMap={webhooksLoadingMap}
        initiallyLoaded={webhooksInitiallyLoaded}
        onAddWebhook={handleAddWebhook}
        onRemoveWebhook={handleRemoveWebhook}
      />
    </Root>
  )
}

const Root = styled(Paper)`
  padding: 30px;
`

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

const HeadTitle = styled(Typography)`
  margin-right: 40px;
`

const ChipsContainer = styled.div`
  margin-bottom: 70px;
`
