import { State } from '../reducers/types'
import { User, UserActivity, ActivityLog } from '../interfaces/users'
import { Companies } from '@para-components/constants/argyle'

export const getUsers = (state: State): Array<User> => state.users.list

export const getLastClickedUser = (state: State): User | null => state.users.lastClickedUser

export const getSelectedUsers = (state: State): Array<User> => state.users.selectedUsers

export const getSelectedCompany = (state: State): Companies | 'ALL' | null =>
  state.users.selectedCompany

export const getUserActivities = (state: State): Array<UserActivity> => state.users.activityList

export const getUserActivitiesLoading = (state: State): boolean => state.users.activityListLoading

export const getProcessedActivityLogs = (state: State): Array<ActivityLog> =>
  state.users.processActivitiesLogs
