import { createActions } from '@para-components/utils/reduxUtils'

// Auth
export const SetUser = createActions('SetUser')

export const Login = createActions('Login')

export const Logout = createActions('Logout')

// Webhooks
export const FetchWebkooks = createActions('FetchWebhooks')

export const AddWebhook = createActions('AddWebhook')

export const RemoveWebhook = createActions('RemoveWebhook')

// Users
export const FetchUsers = createActions('FetchUsers')

export const SetLastClickedUser = createActions('SetLastClickedUser')

export const SetSelectedUsers = createActions('SetSelectedUsers')

export const SetSelectedCompany = createActions('setSelectedCompany')

// Activities
export const FetchActivities = createActions('FetchActivities')

export const ProcessActivities = createActions('ProcessActivities')

// Admin Users
export const FetchAdminUsers = createActions('FetchAdminUsers')

export const SetLastClickedAdminUser = createActions('SetLastClickedAdminUser')

export const AddAdminPermission = createActions('AddAdminPermission')

export const RemoveAdminPermission = createActions('RemoveAdminPermission')

// UI
export const ToggleLeftMenu = createActions('ToggleLeftMenu')
