import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Webhook } from '@admin/interfaces/webhooks'
import { AvailableWebhooks } from '@admin/constants/webhooks'
import { WebhookItem } from './WebhookItem'

interface Props {
  data: Array<Webhook>
  webhooksLoadingMap: WebhooksLoadingMap
  initiallyLoaded: boolean
  onAddWebhook(key: AvailableWebhooks): Promise<void>
  onRemoveWebhook(key: AvailableWebhooks, id: string): Promise<void>
}

export type WebhooksLoadingMap = { [key in keyof typeof AvailableWebhooks]: boolean }

export const WebhooksList = ({
  data,
  webhooksLoadingMap,
  initiallyLoaded,
  onAddWebhook,
  onRemoveWebhook,
}: Props) => {
  if (!initiallyLoaded) {
    return (
      <ProgressContainer>
        <CircularProgress />
      </ProgressContainer>
    )
  }

  return (
    <Root>
      {Object.keys(AvailableWebhooks).map((key) => (
        <WebhookItem
          key={key}
          title={key as AvailableWebhooks}
          data={data.find((item) => item.name === key)}
          isLoading={webhooksLoadingMap[key as AvailableWebhooks]}
          onAddWebhook={onAddWebhook}
          onRemoveWebhook={onRemoveWebhook}
        />
      ))}
    </Root>
  )
}

const Root = styled.div`
  max-width: 760px;
  margin: 0 auto;
`

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
`
