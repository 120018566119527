import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'
import moment from 'moment'
import {
  getUserActivities,
  getUserActivitiesLoading,
  getSelectedCompany,
  getLastClickedUser,
} from '@admin/selectors/users'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import FilterListIcon from '@material-ui/icons/FilterList'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Paper } from '@admin/components/layout/Paper'
import { FetchActivities } from '@admin/constants/actionTypes'
import { AccountId } from '@admin/interfaces/users'
import { DATE_FORMAT } from '@admin/constants/date'
import { createAsyncAction } from '@para-components/utils/reduxUtils'
import { UserActivitiesGrid } from './UserActivitiesGrid'
import UserActivitiesFilterForm from './FilterForm'
import { FilterFormValues } from './FilterForm.interfaces'
import { validateForm } from './FilterForm.validation'

const initialValues: FilterFormValues = {
  startTime: '',
  endTime: '',
}

interface Props {
  className?: string
}

export const UserActivities = ({ className }: Props) => {
  const dispatch = useDispatch()

  const activityList = useSelector(getUserActivities)
  const activityListLoading = useSelector(getUserActivitiesLoading)
  const selectedCompany = useSelector(getSelectedCompany)
  const lastClickedUser = useSelector(getLastClickedUser)

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [submittedFilterData, setFilterData] = useState<FilterFormValues | null>(null)
  const open = Boolean(anchorEl)

  const accountIds = useMemo(
    () =>
      selectedCompany === 'ALL'
        ? lastClickedUser?.accountIds
        : [
            lastClickedUser?.accountIds.find(
              (accountId: AccountId) => accountId.dataPartner === selectedCompany,
            ),
          ],
    [selectedCompany, lastClickedUser],
  )

  useEffect(() => {
    setFilterData(null)
  }, [selectedCompany, lastClickedUser])

  const handleOpenFilterFormClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (lastClickedUser && selectedCompany) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleFilterFormClose = () => {
    setAnchorEl(null)
  }

  const handleFilterFormSubmit = async (values: FilterFormValues) => {
    setError(null)
    setFilterData(values)
    try {
      await createAsyncAction(
        dispatch,
        FetchActivities.request({
          accountIds,
          fromStartDate: moment(values.startTime, DATE_FORMAT).toISOString(),
          toStartDate: moment(values.endTime, DATE_FORMAT).toISOString(),
        }),
      )
      handleFilterFormClose()
    } catch (err) {
      setError(err)
    }
  }

  const updateActivities = async () => {
    try {
      await createAsyncAction(
        dispatch,
        FetchActivities.request({
          accountIds,
        }),
      )
      handleFilterFormClose()
    } catch (err) {
      setError(err)
    }
  }

  const formValidator = (values: FilterFormValues) => validateForm(values)

  return (
    <Root className={className}>
      <HeadContainer>
        <Typography variant="h4" color="textPrimary">
          Actvities of selected account
        </Typography>
        <FiltersContainer>
          <FilterLogoWrapper onClick={handleOpenFilterFormClick}>
            <FilterListIcon />
            <FilterLogoTitle>Filter</FilterLogoTitle>
          </FilterLogoWrapper>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleFilterFormClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Formik
              initialValues={submittedFilterData || initialValues}
              onSubmit={handleFilterFormSubmit}
              validate={formValidator}
              onReset={updateActivities}
              validateOnChange
              validateOnBlur
              enableReinitialize
            >
              <UserActivitiesFilterForm
                isLoading={activityListLoading}
                error={error}
                setFilterData={setFilterData}
              />
            </Formik>
          </Popover>
        </FiltersContainer>
      </HeadContainer>
      {activityListLoading ? (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      ) : (
        <UserActivitiesGrid rows={activityList} />
      )}
    </Root>
  )
}

const Root = styled(Paper)`
  padding: 24px 0 6px;
`

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  margin-bottom: 30px;
`

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
`

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 30px;
`
const FilterLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 50px;
  cursor: pointer;
`

const FilterLogoTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4b506d;
  margin-left: 10px;
`
