import { put, call, takeEvery } from 'redux-saga/effects'
import { FetchUsers, AddAdminPermission } from '@admin/constants/actionTypes'
import { FunctionKeys } from '@admin/constants/functions'
import { AsyncAction } from '@para-components/utils/reduxUtils'
import { User } from '@admin/interfaces/users'
import { callSecureApi } from './api/callApi'

function* onFetchUsers(action: AsyncAction<unknown>) {
  try {
    const { payload } = yield call(callSecureApi, FunctionKeys.GetUsers)

    const userListWithId = payload.map((user: Omit<User, 'id'>) => ({ id: user.userId, ...user }))

    yield put(FetchUsers.success(userListWithId))
    if (action.next) {
      action.next()
    }
  } catch (err) {
    yield put(FetchUsers.failed(err))
    if (action.next) {
      action.next(err)
    }
  }
}

interface AddAdminPermissionRequest {
  userId: string
}

function* onAddAdminPermission(action: AsyncAction<AddAdminPermissionRequest>) {
  try {
    yield call(callSecureApi, FunctionKeys.AddAdminPermission, {
      method: 'POST',
      body: {
        userId: action.payload.userId,
      },
    })

    yield put(AddAdminPermission.success({ userId: action.payload.userId }))
    if (action.next) {
      action.next()
    }
  } catch (err) {
    yield put(AddAdminPermission.failed(err))
    if (action.next) {
      action.next(err)
    }
  }
}

export function* usersSaga() {
  yield takeEvery(FetchUsers.type.REQUEST, onFetchUsers)
  yield takeEvery(AddAdminPermission.type.REQUEST, onAddAdminPermission)
}
