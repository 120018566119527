import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getLastClickedAdminUser } from '@admin/selectors/adminUsers'
import { SecurePage } from '@admin/components/layout/SecurePage'
import { AdminUsersList } from '@admin/components/admin-users/admin-users-list'
import { AdminUserDetails } from '@admin/components/admin-users/admin-user-details'

export const AdminUsersPage = () => {
  const lastClickedUser = useSelector(getLastClickedAdminUser)

  return (
    <SecurePage>
      <Container>
        <UsersRow>
          <StyledUsersList noOffset={!lastClickedUser} />
          <StyledUserDetails />
        </UsersRow>
      </Container>
    </SecurePage>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
`

const UsersRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`

const StyledUsersList = styled(AdminUsersList)<{ noOffset?: boolean }>`
  flex: 1;
  ${({ noOffset }) => (noOffset ? '' : 'margin-right: 20px;')}
`

const StyledUserDetails = styled(AdminUserDetails)`
  flex: 0 0 340px;
`
